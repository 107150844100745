import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Welcome to our API documentation, we have two sets of APIs, Server to Server or also known as REST API, and our frontend Javascript API.
These are two separate APIs and provide you the flexibility to customize B2B Ninja to meet your needs.`}</p>
    <h2 {...{
      "id": "server-to-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#server-to-server",
        "aria-label": "server to server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server to Server`}</h2>
    <p>{`Our goal is to provide you with complete access to everything a regular user could do using the Dashboard via the API.
We give you access to all the fields on a quote, so it can be overwhelming if you look at the data.
If something is not clear, please chat us and we will do our best to clarify and improve our documentation.`}</p>
    <p>{`We also have a Zapier integration in beta that might be easier to use if you are not a developer. `}<a parentName="p" {...{
        "href": "https://zapier.com/developer/public-invite/104837/f3fd40a5b8f46cf798609a54775e3364/"
      }}>{`Click here`}</a>{` to add the integration to your Zapier Account or Copy the url below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-url"
      }}>{`https://zapier.com/developer/public-invite/104837/f3fd40a5b8f46cf798609a54775e3364/
`}</code></pre>
    <h3 {...{
      "id": "outline-of-the-capabilities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outline-of-the-capabilities",
        "aria-label": "outline of the capabilities permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outline of the Capabilities`}</h3>
    <ul>
      <li parentName="ul">{`✨ Create a new Quote`}</li>
      <li parentName="ul">{`📑 Update an existing Quote`}</li>
      <li parentName="ul">{`📨 Email Quotes`}</li>
      <li parentName="ul">{`📜 Get list of templates`}</li>
      <li parentName="ul">{`👥 Get list of users`}</li>
      <li parentName="ul">{`🕵 Search & Filter quotes`}</li>
      <li parentName="ul">{`and much more 🔥`}</li>
    </ul>
    <br />
    <p><a parentName="p" {...{
        "href": "/server-api/getting-started"
      }}>{`Get started with Server API now!`}</a></p>
    <h2 {...{
      "id": "frontend-javascript",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#frontend-javascript",
        "aria-label": "frontend javascript permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frontend JavaScript`}</h2>
    <p>{`This API allows you to build custom storefront experiences for your customers. For example, it allows you to leverage B2B Ninja to provide them with custom quick quote pages, category/search page buttons, or even use B2B Ninja on headless BigCommerce sites.`}</p>
    <h3 {...{
      "id": "outline-of-the-capabilities-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outline-of-the-capabilities-1",
        "aria-label": "outline of the capabilities 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outline of the Capabilities`}</h3>
    <ul>
      <li parentName="ul">{`✨ Add Product(s) to Quote`}</li>
      <li parentName="ul">{`📑 Convert Cart To Quote`}</li>
      <li parentName="ul">{`📨 Open Current Quote`}</li>
      <li parentName="ul">{`📜 Get items on Quote to update Item Count`}</li>
      <li parentName="ul">{`👥 Show logged in user's quotes`}</li>
      <li parentName="ul">{`and much more 🔥`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "/storefront-api/reference"
      }}>{`Get started with JavaScript API now!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      